@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Garnett' , 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Garnett';
  src: url(./assets/fonts/GarnettTRIAL-Semibold.woff2) format('woff2'),
      url(./assets/fonts/GarnettTRIAL-Semibold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Garnett';
  src: url(./assets/fonts/GarnettTRIAL-Light.woff2) format('woff2'),
      url(./assets/fonts/GarnettTRIAL-Light.woff2) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Garnett';
  src: url(./assets/fonts/GarnettTRIAL-Regular.woff2) format('woff2'),
      url(./assets/fonts/GarnettTRIAL-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Garnett';
  src: url(./assets/fonts/GarnettTRIAL-Bold.woff) format('woff2'),
      url(./assets/fonts/GarnettTRIAL-Bold.woff2) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LiSong Pro';
  src: url(./assets/fonts/LiSongPro.woff2) format('woff2'),
      url(./assets/fonts/LiSongPro.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Druk Wide';
  src: url(./assets/fonts/Druk-WideMedium.woff2) format('woff2'),
      url(./assets/fonts/Druk-WideMedium.woff) format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
}